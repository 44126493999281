import React from "react";
import loadable from "@loadable/component";
import { webApplicationFAQList } from "../components/ApplicationsDevelopment/WebDevelopment/components/FAQSection/FAQSection";

const Layout = loadable(() => import("@common/Layout/Layout"));
const WebDevelopment = loadable(() =>
    import("@components/ApplicationsDevelopment/WebDevelopment/WebDevelopment")
);
const SEO = loadable(() => import("@components/Common/SEO/SEO"));

const WebDevelopmentPage = ({ path }) => (
    <>
        <SEO
            title="Custom Web App Development Services from a Reliable Tech Partner"
            titleTemplate="Impressit"
            description="Hire a team with Impressit for custom web app development services. From the kickoff call to launch — we are eager to turn your idea into a brilliant product."
            path={path}
            faqSchema={webApplicationFAQList}
        />
        <Layout path={path}>
            <WebDevelopment />
        </Layout>
    </>
);

export default WebDevelopmentPage;
